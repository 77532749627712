const initialState = {
  toggle: false,
};

const darkMode = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_SWITCH_DARK_MODE': {
      const toggle = !state.toggle;
      return {
        ...state,
        toggle,
      };
    }
    default:
      return state;
  }
};

export default darkMode;