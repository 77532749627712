import { combineReducers } from 'redux';
import darkMode from './darkModeReducer';
import lmsAds from './lmsAdsReducer';
import megaMenu from './megaMenuReducer';
import premiumAds from './premiumAdsReducer';

export default combineReducers({
  darkMode: darkMode,
  lmsAds: lmsAds,
  megaMenu: megaMenu,
  premiumAds: premiumAds,
});
