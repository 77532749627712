import i18n from '../i18n';
import { translate } from 'react-i18next';
import Cookies from 'js-cookie';

const func = (
  namespaces = ['common'],
  initialLanguage = 'id',
) => ComposedComponent => {

  // Override Initial Language, when no lang selected on current session
  const cookieLanguage = Cookies.get('lang');
  // console.log('cookie', cookieLanguage);
  // console.log('current', i18n.language);
  // console.log('prefered', initialLanguage);
  if(!cookieLanguage && initialLanguage) {
    // console.log('change lang', initialLanguage);
    i18n.changeLanguage(initialLanguage)
  }

  const Extended = translate(namespaces, { i18n: i18n, wait: process.browser })(
    ComposedComponent,
  );

  return Extended;
};

export const localize = func
export const withLocalize = func
