import MobileDetect from 'mobile-detect';
import { withRouter } from 'next/router';
import jwt from 'jsonwebtoken';
import i18n from '../i18n';
import { withLocalize } from './localize';
import { withRedux } from './redux';
import { getJwt } from './auth';

// retrieve configuration
const getConfig = require('next/config').default;

const { publicRuntimeConfig } = getConfig();
const { BASE_URL, USE_NEW_BASE_URL } = publicRuntimeConfig;

const func = (ComposedComponent, opts = {}) => {
  // add localize props
  const composedByLocalize = withLocalize(opts.namespaces, opts.initialLanguage)(
    ComposedComponent,
  );

  // add router props
  const composedByRouter = withRouter(composedByLocalize);

  // add redux props
  const composedByRedux = withRedux(composedByRouter);

  composedByRedux.getInitialProps = async (ctx) => {
    const { req } = ctx;

    // add custom initial props provided
    const composedInitialProps = ComposedComponent.getInitialProps
      ? await ComposedComponent.getInitialProps(ctx)
      : {};

    // add mobile detection props
    const mobileDetect = new MobileDetect(
      ctx.req
        ? ctx.req.headers['user-agent']
        : 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36',
    );
    const mobileDetectionProps = {
      isMobile: !!mobileDetect.mobile(),
    };

    // add jwt props
    const jwtToken = await getJwt(ctx);
    const decoded = jwt.decode(jwtToken) || {};
    const parsedUserProducts = JSON.parse(decoded.ups || '[{}]');
    const { product = '', end_date = '' } = parsedUserProducts[0] != null ? parsedUserProducts[0] : {};

    const jwtProps = {
      jwt: jwtToken,
      user: decoded.aud,
      token: jwtToken,
      user_identity: {
        username: decoded.unm ? decoded.unm : '',
        groups: decoded.aud ? decoded.aud : '',
        subscription_expired_at: decoded.sbx ? decoded.sbx : '',
        products: decoded.ups ? decoded.ups :  {}
      },
      products: {
        product,
        end_date,
      },
    };

    // add url props
    let currentUrl;
    if (req) { // server side rendering
      if (req.get('host') === 'app.hukumonline.com') currentUrl = `${req.protocol}://hukumonline.com${req.originalUrl}`;
      else currentUrl = `${req.protocol}://${req.get('host')}${req.originalUrl}`;

      // this code is to change from url that containts prov1.hukumonline.com to pro.hukumonline.com
      // this issue is caused by returnURL always return prov1.hukumonline.com on production because get host on server side rendering
      // which is prov1.hukumonline.com is domain the server that from domain proxy pro.hukumonline.com
      // ticket for the issue is https://hukumonline.atlassian.net/browse/PCP-435
      if (currentUrl.includes('prov1.')) {
        currentUrl = currentUrl.replace('prov1.', 'pro.')
      };
      if(currentUrl.includes('core-pusatdata-svc:80')) {
        currentUrl = currentUrl.replace('core-pusatdata-svc:80', 'hukumonline.com')
      }
    } else { // client side rendering
      currentUrl = window.location.href;
    }
    const urlProps = {
      baseUrl: BASE_URL,
      currentUrl,
      useNewBaseUrl: USE_NEW_BASE_URL,
    };

    const i18nInitialProps = ctx.req && !process.browser
      ? i18n.getInitialProps(ctx.req, opts.namespaces)
      : {};

    return {
      ...composedInitialProps,
      ...mobileDetectionProps,
      ...jwtProps,
      ...urlProps,
      ...i18nInitialProps,
    };
  };

  return composedByRedux;
};

export const withCommons = func;
export const withCommon = func;
