import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducer from './reducers';

const persistConfig = {
  key: 'primary',
  storage,
  whitelist: [
    'megaMenu',
    'darkMode',
    'premiumAds',
    'lmsAds',
  ],
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const initializeStore = (preloadedState = {}) => createStore(
  persistedReducer,
  preloadedState,
  composeWithDevTools(applyMiddleware(thunk)),
);
