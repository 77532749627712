const initialState = {
  data: null,
  updatedAt: null,
};

const megaMenu = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_DATA_MEGA_MENU': {
      return {
        ...state,
        data: action.data,
        updatedAt: action.updatedAt,
      };
    }
    default:
      return state;
  }
};

export default megaMenu;